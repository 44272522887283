/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

.addon + .btn {
    position: absolute;
    z-index: 10;
}

.addon-S + .btn {
    top: 5%;
    left: -5%;
}

.addon-X + .btn,
.addon-W + .btn {
    top: 35%;
    left: 5%;
}
