/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

#configuration .module button {
    position: absolute;
    margin: 15px;
    z-index: 10;
}

#configuration .module {
    display: flex;
    position: relative;
}

#configuration .module img,
#configuration .module svg {
    max-height: 300px;
}
