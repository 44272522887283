/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

#modules-select-bar {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    overflow: auto;
    align-items: stretch;
}

#modules-select-bar img,
#modules-select-bar svg {
    max-height: 150px;
}

#modules-select-bar svg {
    top: 3%;
}

#modules-select-bar .module {
    display: flex;
    min-width: 85px;
    height: 210px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

#modules-select-bar .module img {
    -webkit-filter: drop-shadow(2px -2px 3px rgba(0, 0, 0, 0.51));
    filter: drop-shadow(2px -2px 3px rgba(0, 0, 0, 0.51));
    margin: 0.5rem 0.5rem;
}

#modules-select-bar .module-S img {
    max-height: 100px;
}

#modules-select-bar .module-X img,
#modules-select-bar .module-W img {
    max-height: 80px;
}

#modules-select-bar .module-P img {
    max-height: 145px;
}
