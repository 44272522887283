/*
 * Copyright (C) 2019-2023 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

.module {
    position: relative;
    display: inline-block;
}

.module > img {
    position: relative;
    -webkit-filter: drop-shadow(4px -4px 5px rgba(0, 0, 0, 0.51));
    filter: drop-shadow(4px -4px 5px rgba(0, 0, 0, 0.51));
}

.module-buses svg {
    z-index: 1;
    -webkit-filter: drop-shadow(0px 0px 8px rgb(0, 52, 46));
    filter: drop-shadow(0px 0px 8px rgb(0, 52, 46));
    position: absolute;
    top: 0;
    left: 0;
}

.module .addon {
    z-index: 2;
    position: absolute;
}

.module .addon-placeholder {
    -webkit-filter: grayscale(1) brightness(1.25) opacity(0.5);
    filter: grayscale(1) brightness(1.25) opacity(0.5);
}

.module .addon-S {
    top: 14.5%;
    left: 12%;
    width: 44%;
}

.module .addon-W {
    top: 43.5%;
    left: 21.5%;
    width: 51%;
    clip: rect(0px, 80px, 125px, 0px);
}

.module .addon-X {
    top: 46.5%;
    left: 21.5%;
    width: 51%;
    clip: rect(0px, 80px, 118px, 0px);
}

.module-inactive {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}
